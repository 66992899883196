import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../../providers/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { LicenceRequest } from '../../../api/models';
import Grid from '@mui/material/Grid2';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

type Props = {
  show: boolean;
  close: () => void;
};

const AddLicenceForm: React.FC<Props> = ({ show, close }) => {
  const { t } = useTranslation();
  const adminStore = useAdminStore();

  const validationSchema = yup.object({
    facilityId: yup
      .number()
      .required(t('validation.required'))
      .min(1, t('validation.required')),
    validTo: yup.string().required(t('validation.required')),
  });

  const formik = useFormik<LicenceRequest>({
    initialValues: {
      facilityId: adminStore.customer?.facilities
        ? adminStore.customer?.facilities[0].id
        : 0,
      validTo: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (adminStore.customer && adminStore.customer.id) {
        adminStore.createLicence(adminStore.customer.id, values, () => {
          close();
        });
      }
    },
  });

  let facility;
  if (formik.values.facilityId) {
    facility = adminStore.customer?.facilities?.find(
      (f) => f.id === formik.values.facilityId,
    );
  }

  return (
    <Dialog
      open={show}
      onClose={close}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        },
      }}
    >
      <DialogTitle>{t('admin.addLicence')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} pt={2}>
          <Grid size={12}>
            <Autocomplete
              fullWidth
              id="facilityId"
              disableClearable
              value={facility}
              onChange={(e, value) =>
                formik.setFieldValue('facilityId', value?.id || undefined)
              }
              options={adminStore.customer?.facilities || []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('licence.facility')}
                  variant="outlined"
                  error={
                    formik.touched.facilityId &&
                    Boolean(formik.errors.facilityId)
                  }
                  helperText={
                    formik.touched.facilityId && formik.errors.facilityId
                  }
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                      required: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              id="validTo"
              name="validTo"
              label={t('licence.validTo')}
              value={formik.values.validTo}
              onChange={formik.handleChange}
              error={formik.touched.validTo && Boolean(formik.errors.validTo)}
              helperText={formik.touched.validTo && formik.errors.validTo}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
                htmlInput: {
                  onFocus: (event: ChangeEvent<HTMLInputElement>) => {
                    event.target.showPicker();
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('general.close')}</Button>
        <Button type="submit">{t('general.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLicenceForm;
