import { ExportType } from './models';

const publicURL = () => {
  return '/public';
};

const protectedURL = () => {
  return '/protected';
};

const privateURL = () => {
  return '/private';
};

// Auth

export const loginURL = () => {
  return `${publicURL()}/users/login`;
};

export const passwordResetURL = () => {
  return `${publicURL()}/users/password/reset`;
};

export const passwordChangeURL = (token: string) => {
  return `${publicURL()}/users/password/change?token=${token}`;
};

export const registerURL = (
  refUser: string | null,
  refCustomer: string | null,
) => {
  let params = '';
  if (refUser !== null && refCustomer !== null) {
    params = `?ref_user=${refUser}&ref_customer=${refCustomer}`;
  }
  return `${publicURL()}/users/register${params}`;
};

export const mailConfirmURL = (token: string) => {
  return `${publicURL()}/users/mail/token/valid?token=${token}`;
};

// Billing

export const countriesURL = () => {
  return `${publicURL()}/countries`;
};

export const userURL = () => {
  return `${protectedURL()}/users/me`;
};

export const customerMeURL = () => {
  return `${protectedURL()}/customers/me`;
};

export const facilitiesURL = () => {
  return `${protectedURL()}/facilities`;
};

export const licencesURL = () => {
  return `${protectedURL()}/licences`;
};

export const subscriptionsURL = () => {
  return `${licencesURL()}/subscriptions`;
};

export const subscriptionInfoURL = () => {
  return `${subscriptionsURL()}/info`;
};

export const couponInfoURL = () => {
  return `${subscriptionsURL()}/coupons/info`;
};

export const referralsURL = () => {
  return `${protectedURL()}/referrals/info`;
};

export const licencesReferrlsURL = () => {
  return `${licencesURL()}/referrals`;
};

export const cancelStripeSubscriptionURL = (id: string) => {
  return `${protectedURL()}/stripe/subscription/${id}`;
};

// Admin

export const customersURL = () => {
  return `${privateURL()}/customers`;
};

export const customerURL = (customerId: number) => {
  return `${privateURL()}/customers/${customerId}`;
};

export const customerUsersURL = (customerId: number) => {
  return `${customerURL(customerId)}/users`;
};

export const customerEvisitorsURL = (customerId: number) => {
  return `${customerURL(customerId)}/evisitors`;
};

export const customerEvisitorURL = (customerId: number, id: number) => {
  return `${customerURL(customerId)}/evisitors/${id}`;
};

export const customerEturizemAccountsURL = (customerId: number) => {
  return `${customerURL(customerId)}/eturizem/accounts`;
};

export const customerEturizemAccountURL = (customerId: number, id: number) => {
  return `${customerURL(customerId)}/eturizem/accounts/${id}`;
};

export const customerFacilitiesURL = (customerId: number) => {
  return `${customerURL(customerId)}/facilities`;
};

export const customerLicencesURL = (customerId: number) => {
  return `${customerURL(customerId)}/licences`;
};

export const userUndeleteURL = (userId: number) => {
  return `${privateURL()}/users/${userId}/undelete`;
};

export const userPasswordChangeURL = (userId: number) => {
  return `${privateURL()}/users/${userId}/password/change`;
};

export const exportURL = (type: ExportType) => {
  return `${privateURL()}/export/${type}`;
};
