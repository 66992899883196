import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore, useBillingStore } from '../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import LicenceList from '../../components/billing/list/LicenceList';
import SubscriptionForm from '../../components/billing/form/SubscriptionForm';

const Subscription: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const billingStore = useBillingStore();

  const hasActiveLicences =
    billingStore.facilities &&
    billingStore.licences &&
    billingStore.licences.length > 0;

  const hasPaymentInfo =
    mainStore.customer &&
    billingStore.facilities &&
    billingStore.licencePaymentInfos &&
    billingStore.licencePaymentInfos.length > 0;

  return (
    <Grid container spacing={10}>
      {hasActiveLicences && (
        <Grid size={12}>
          <Grid container spacing={4}>
            <Grid size={12}>
              <Typography variant="h4">{t('home.activeLicences')}</Typography>
            </Grid>
            <Grid size={12}>
              <LicenceList />
            </Grid>
          </Grid>
        </Grid>
      )}
      {hasPaymentInfo && (
        <Grid size={12}>
          <Grid container spacing={4}>
            <Grid size={12}>
              <Typography variant="h4">{t('home.renewLicences')}</Typography>
            </Grid>
            <Grid size={12}>
              <SubscriptionForm />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default observer(Subscription);
