import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAdminStore } from '../../providers/StoresProvider';

import CustomerList from './list/CustomerList';
import CustomerView from './view/CustomerView';

const Customers: React.FC = () => {
  const adminStore = useAdminStore();

  useEffect(() => {
    if (adminStore.customers === undefined) {
      adminStore.getCustomers();
    }
  }, [adminStore]);

  return adminStore.customer ? <CustomerView /> : <CustomerList />;
};

export default observer(Customers);
