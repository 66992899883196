import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from 'mobx';
import {
  StatusResponse,
  Country,
  Customer,
  Facility,
  Licence,
  LicenceFetchParams,
  Subscription,
  SubscriptionRequest,
  LicencePaymentInfo,
  ReferralInfo,
  CouponInfo,
  CouponInfoFetchParams,
  PaymentMethod,
} from '../api/models';
import {
  countriesURL,
  facilitiesURL,
  licencesURL,
  subscriptionsURL,
  subscriptionInfoURL,
  referralsURL,
  licencesReferrlsURL,
  couponInfoURL,
  cancelStripeSubscriptionURL,
} from '../api/urls';
import { getJSON, postJSON, deleteJSON } from '../api/engine';
import { getTaxInfo } from '../api/spaceInvoice';
import { StripeError } from '@stripe/stripe-js';
import MainStore from './MainStore';

class BillingStore {
  mainStore: MainStore;

  // Observable
  loader = false;
  activeStep = 0;

  licences: Licence[] | null = null;
  licencePaymentInfos: LicencePaymentInfo[] | null = null;
  selectedPaymentInfos: LicencePaymentInfo[] = [];

  subscription: Subscription | null = null;
  subscriptions: Subscription[] | null = null;

  paymentMethod: PaymentMethod = PaymentMethod.CARD;
  paymentError: StripeError | null = null;

  referralInfo: ReferralInfo | null = null;

  couponInfo: CouponInfo | null = null;

  facilities: Facility[] | null = null;

  countries: Country[] | null = null;

  taxNumberValid = true;

  // Other
  priceFormatter = new Intl.NumberFormat('hr-HR', {
    style: 'currency',
    currency: 'EUR',
  });

  stepperRef = React.createRef<HTMLDivElement>();

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;

    makeObservable(this, {
      loader: observable,
      activeStep: observable,
      licences: observable,
      subscription: observable,
      subscriptions: observable,
      paymentMethod: observable,
      paymentError: observable,
      licencePaymentInfos: observable,
      selectedPaymentInfos: observable,
      referralInfo: observable,
      couponInfo: observable,
      facilities: observable,
      countries: observable,
      taxNumberValid: observable,

      showLoader: action,
      hideLoader: action,
      setStep: action,
      nextStep: action,
      previousStep: action,
      setPaymentMethod: action,
      setPaymentError: action,
      validateTaxNumber: action,
      getFacilities: action,
      getLicences: action,
      getSubscriptions: action,
      getSubscriptionInfo: action,
      cancelStripeSubscription: action,
      getReferralsInfo: action,
      getCountries: action,
      selectPaymentInfos: action,
      getCouponInfo: action,

      totalPrice: computed,
      subscriptionRange: computed,
      invalidCupon: computed,
      validCupon: computed,
      couponDiscount: computed,
    });
  }

  // Actions

  validateTaxNumber(customer: Customer, callback: (valid: boolean) => void) {
    if (customer.taxSubject) {
      const taxNumber = customer.companyIdentificationNumber;
      const countryId = customer.countryId;
      if (!taxNumber || !countryId) {
        this.taxNumberValid = false;
        callback(false);
        return;
      }

      const country = this.countries?.find((c) => c.id === countryId);
      if (country) {
        this.showLoader();
        getTaxInfo(
          {
            country: country.name,
            taxNumber: taxNumber,
          },
          (taxInfo) => {
            runInAction(() => {
              if (taxInfo !== null) {
                if (
                  taxInfo?.taxClauseType === 'eu_b2b' ||
                  taxInfo?.taxClauseType === 'local'
                ) {
                  this.taxNumberValid = true;
                  callback(true);
                } else {
                  this.taxNumberValid = false;
                  callback(false);
                }
              }
              this.hideLoader();
            });
          },
        );
      }
    } else {
      this.taxNumberValid = true;
      callback(true);
    }
  }

  getFacilities() {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      getJSON<Facility[]>(
        this.mainStore.baseUrl,
        facilitiesURL(),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.facilities = response.data;
          }
          this.hideLoader();
        });
      });
    }
  }

  getLicences() {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      getJSON<Licence[], LicenceFetchParams>(
        this.mainStore.baseUrl,
        licencesURL(),
        {
          valid: true,
        },
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.licences = response.data;
          }
          this.hideLoader();
        });
      });
    }
  }

  getSubscriptions() {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      getJSON<Subscription[]>(
        this.mainStore.baseUrl,
        subscriptionsURL(),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.subscriptions = response.data;

            response.data.sort((a, b) => b.id - a.id);

            this.subscription = response.data[0];
          }
          this.hideLoader();
        });
      });
    }
  }

  createSubscription(
    postData: SubscriptionRequest,
    callback: (data: Subscription | null) => void,
  ) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      postJSON<Subscription, SubscriptionRequest>(
        this.mainStore.baseUrl,
        subscriptionsURL(),
        postData,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            if (!this.subscriptions) {
              this.subscriptions = [];
            }
            this.subscriptions.push(response.data);

            this.subscription = response.data;

            callback(response.data);
          } else {
            callback(null);
          }
          this.hideLoader();
        });
      });
    }
  }

  getSubscriptionInfo() {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      getJSON<LicencePaymentInfo[]>(
        this.mainStore.baseUrl,
        subscriptionInfoURL(),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.licencePaymentInfos = response.data;
            this.selectPaymentInfos(response.data);
          }
          this.hideLoader();
        });
      });
    }
  }

  cancelStripeSubscription(id: string, callback: () => void) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      deleteJSON<void>(
        this.mainStore.baseUrl,
        cancelStripeSubscriptionURL(id),
        this.mainStore.authToken,
      ).then(() => {
        runInAction(() => {
          this.hideLoader();
          callback();
        });
      });
    }
  }

  selectPaymentInfos(infos: LicencePaymentInfo[]) {
    this.selectedPaymentInfos = infos;
  }

  getReferralsInfo() {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      getJSON<ReferralInfo>(
        this.mainStore.baseUrl,
        referralsURL(),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.referralInfo = response.data;
          }
          this.hideLoader();
        });
      });
    }
  }

  getCouponInfo(code: string) {
    if (this.mainStore.authToken !== null) {
      if (code !== '') {
        this.showLoader();

        getJSON<CouponInfo, CouponInfoFetchParams>(
          this.mainStore.baseUrl,
          couponInfoURL(),
          { code },
          this.mainStore.authToken,
        ).then((response) => {
          runInAction(() => {
            if (response.success && response.data) {
              this.couponInfo = response.data;
            }
            this.hideLoader();
          });
        });
      } else {
        this.couponInfo = null;
      }
    }
  }

  createReferralsLicences(callback: (success: boolean) => void) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      postJSON<StatusResponse, undefined>(
        this.mainStore.baseUrl,
        licencesReferrlsURL(),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.getLicences();
            this.getReferralsInfo();
            this.getSubscriptionInfo();
            callback(response.data.success);
          } else {
            callback(false);
          }
          this.hideLoader();
        });
      });
    }
  }

  getCountries() {
    this.showLoader();

    getJSON<Country[]>(this.mainStore.baseUrl, countriesURL()).then(
      (response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.countries = response.data;
          }
          this.hideLoader();
        });
      },
    );
  }

  clear() {
    this.facilities = null;
    this.licences = null;
    this.licencePaymentInfos = null;
    this.referralInfo = null;
    this.subscriptions = null;
  }

  showLoader() {
    this.loader = true;
  }

  hideLoader() {
    this.loader = false;
  }

  setStep(step: number) {
    this.activeStep = step;
  }

  nextStep() {
    this.stepperRef.current?.scrollIntoView();
    this.activeStep++;
  }

  previousStep() {
    this.stepperRef.current?.scrollIntoView();
    this.activeStep--;
  }

  setPaymentMethod(method: PaymentMethod) {
    this.paymentMethod = method;
  }

  setPaymentError(error: StripeError) {
    this.paymentError = error;
  }

  // Computeds

  get totalPrice() {
    let total = 0;
    this.selectedPaymentInfos?.forEach((i) => {
      total += i.price;
    });

    return this.priceFormatter.format(total / 100);
  }

  get subscriptionRange() {
    if (this.selectedPaymentInfos.length > 0) {
      let minPaymentValid = this.selectedPaymentInfos[0];

      this.selectedPaymentInfos.forEach((p) => {
        if (p.validFrom < minPaymentValid.validFrom) {
          minPaymentValid = p;
        }
      });

      return {
        start: minPaymentValid.validFrom,
        end: minPaymentValid.validTo,
      };
    }
    return {
      start: null,
      end: null,
    };
  }

  get invalidCupon() {
    if (this.couponInfo === null) {
      return false;
    }
    return !this.couponInfo.valid;
  }

  get validCupon() {
    if (this.couponInfo === null) {
      return false;
    }
    return this.couponInfo.valid;
  }

  get couponDiscount() {
    if (this.couponInfo?.amountOff) {
      return this.priceFormatter.format(this.couponInfo?.amountOff / 100);
    } else {
      return `${this.couponInfo?.percentOff}%`;
    }
  }
}

export default BillingStore;
