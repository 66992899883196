import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useBillingStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ReferralForm: React.FC = () => {
  const { t } = useTranslation();
  const billingStore = useBillingStore();

  const handleUseBonusDays = () => {
    billingStore.createReferralsLicences((success) => {
      if (success) {
        alert(t('home.referralFinish'));
      }
    });
  };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid size={12}>
        <Grid container spacing={2}>
          <Grid size={5}>
            <Typography variant="body1">{t('home.forInvited')}</Typography>
          </Grid>
          <Grid size={7}>
            <Typography variant="body1">
              {billingStore.referralInfo?.invited ? 30 : 0}
            </Typography>
          </Grid>
          <Grid size={5}>
            <Typography variant="body1">
              {t('home.forReferrals')}{' '}
              {'(' + billingStore.referralInfo?.referrals.length + ')'}
            </Typography>
          </Grid>
          <Grid size={7}>
            <Typography variant="body1">
              {billingStore.referralInfo?.referrals &&
                billingStore.referralInfo?.referrals.length * 60}
            </Typography>
          </Grid>
          <Grid size={5}>
            <Typography variant="body1">
              <b>{t('home.bonusDays')}</b>
            </Typography>
          </Grid>
          <Grid size={7}>
            <Typography variant="body1">
              <b>{billingStore.referralInfo?.bonusDays}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size="auto">
        <Button
          variant="contained"
          color="primary"
          disabled={billingStore.referralInfo?.bonusDays === 0}
          onClick={handleUseBonusDays}
        >
          {t('home.useBonusDays')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default observer(ReferralForm);
