import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Clear, Visibility } from '@mui/icons-material';

const CustomerList: React.FC = () => {
  const { t } = useTranslation();
  const adminStore = useAdminStore();

  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    adminStore.searchCustomers(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    adminStore.searchCustomers('');
  };

  const handleViewCustomer = (id?: number) => {
    if (id) {
      adminStore.getCustomer(id);
    }
  };

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <Typography variant="h5">{t('admin.customers')}</Typography>
      </Grid>
      <Grid size={12}>
        <TextField
          autoFocus
          label={t('admin.search')}
          variant="outlined"
          value={searchValue}
          onChange={handleSearch}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton onClick={handleClearSearch}>
                  <Clear />
                </IconButton>
              ),
            },
          }}
        />
      </Grid>
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('customer.ownerFirstName')}</TableCell>
                <TableCell>{t('customer.ownerSurname')}</TableCell>
                <TableCell>{t('customer.email')}</TableCell>
                <TableCell>{t('customer.phone')}</TableCell>
                <TableCell>{t('customer.address')}</TableCell>
                <TableCell>{t('customer.zipCode')}</TableCell>
                <TableCell>{t('customer.city')}</TableCell>
                <TableCell>{t('customer.countryId')}</TableCell>
                <TableCell>{t('customer.taxSubject')}</TableCell>
                <TableCell>{t('customer.companyName')}</TableCell>
                <TableCell>
                  {t('customer.companyIdentificationNumber')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.filteredCustomers?.map((customer) => (
                <TableRow
                  key={customer.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{customer.ownerFirstName}</TableCell>
                  <TableCell>{customer.ownerSurname}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{customer.address}</TableCell>
                  <TableCell>{customer.zipCode}</TableCell>
                  <TableCell>{customer.city}</TableCell>
                  <TableCell>{customer.countryId}</TableCell>
                  <TableCell>{customer.taxSubject}</TableCell>
                  <TableCell>{customer.companyName}</TableCell>
                  <TableCell>{customer.companyIdentificationNumber}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleViewCustomer(customer.id)}>
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default observer(CustomerList);
