import React, { PropsWithChildren } from 'react';
import MainStore from '../stores/MainStore';
import BillingStore from '../stores/BillingStore';
import { useLocalObservable } from 'mobx-react';
import AdminStore from '../stores/AdminStore';

const MainStoreContext = React.createContext({} as MainStore);
const BillingStoreContext = React.createContext({} as BillingStore);
const AdminStoreContext = React.createContext({} as AdminStore);

const createMainStore = (): MainStore => {
  return new MainStore();
};

const createBillingStore = (mainStore: MainStore): BillingStore => {
  return new BillingStore(mainStore);
};

const createAdminStore = (mainStore: MainStore): AdminStore => {
  return new AdminStore(mainStore);
};

export const useMainStore = () => {
  const store = React.useContext(MainStoreContext);
  if (!store) {
    throw new Error('useMainStore must be used within a StoresProvider.');
  }
  return store;
};

export const useBillingStore = () => {
  const store = React.useContext(BillingStoreContext);
  if (!store) {
    throw new Error('useBillingStore must be used within a StoresProvider.');
  }
  return store;
};

export const useAdminStore = () => {
  const store = React.useContext(AdminStoreContext);
  if (!store) {
    throw new Error('useAdminStore must be used within a StoresProvider.');
  }
  return store;
};

export const StoresProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const mainStore = useLocalObservable(() => createMainStore());

  const billingStore = useLocalObservable(() => createBillingStore(mainStore));
  const adminStore = useLocalObservable(() => createAdminStore(mainStore));

  return (
    <MainStoreContext.Provider value={mainStore}>
      <BillingStoreContext.Provider value={billingStore}>
        <AdminStoreContext.Provider value={adminStore}>
          {children}
        </AdminStoreContext.Provider>
      </BillingStoreContext.Provider>
    </MainStoreContext.Provider>
  );
};
