import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { ExportType } from '../../api/models';

const Exports: React.FC = () => {
  const { t } = useTranslation();
  const adminStore = useAdminStore();

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <Typography variant="h4">{t('admin.export')}</Typography>
      </Grid>
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            adminStore.export(ExportType.HOSTS);
          }}
        >
          {t('admin.hosts')}
        </Button>
      </Grid>
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            adminStore.export(ExportType.FACILITIES);
          }}
        >
          {t('admin.facilities')}
        </Button>
      </Grid>
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            adminStore.export(ExportType.USERS);
          }}
        >
          {t('admin.users')}
        </Button>
      </Grid>
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            adminStore.export(ExportType.REFERRALS);
          }}
        >
          {t('admin.referrals')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default observer(Exports);
