import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore, useMainStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import LicenceList from '../list/LicenceList';
import FacilityList from '../list/FacilityList';
import UserList from '../list/UserList';
import MiddlewareAccountList from '../list/MiddlewareAccountList';

const CustomerView: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const adminStore = useAdminStore();

  const [openTab, setOpenTab] = React.useState(0);

  const handleBack = () => {
    adminStore.clearCustomer();
  };

  const handleChangeTab = (event: SyntheticEvent, newIndex: number) => {
    setOpenTab(newIndex);
  };

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid size="auto">
            <IconButton onClick={() => handleBack()}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid size="auto">
            <Typography variant="h5">
              {adminStore.customer?.ownerFirstName
                ? `${adminStore.customer?.ownerFirstName} ${adminStore.customer?.ownerSurname} (${adminStore.customer?.email})`
                : adminStore.customer?.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container pl={7}>
          {adminStore.customer?.taxSubject && (
            <Grid size={12}>
              <Typography variant="h5">
                {adminStore.customer?.companyName} (
                {adminStore.customer?.companyIdentificationNumber} )
              </Typography>
            </Grid>
          )}
          {adminStore.customer?.address && (
            <Grid size={12}>
              <Typography>
                {adminStore.customer?.address}
                {', '}
                {adminStore.customer?.zipCode} {adminStore.customer?.city}
              </Typography>
            </Grid>
          )}
          {adminStore.customer?.phone && (
            <Grid size={12}>
              <Typography>{adminStore.customer?.phone}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid size={12}>
        <Tabs value={openTab} onChange={handleChangeTab}>
          <Tab label={t('admin.users')} />
          {mainStore.isSlovenia && <Tab label={t('admin.eturizem')} />}
          {mainStore.isCroatia && <Tab label={t('admin.evisitor')} />}
          <Tab label={t('admin.facilities')} />
          <Tab label={t('admin.licences')} />
        </Tabs>
        <Box hidden={openTab !== 0}>
          <UserList />
        </Box>
        <Box hidden={openTab !== 1}>
          <MiddlewareAccountList />
        </Box>
        <Box hidden={openTab !== 2}>
          <FacilityList />
        </Box>
        <Box hidden={openTab !== 3}>
          <LicenceList />
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(CustomerView);
