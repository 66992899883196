import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid2';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import IosShareIcon from '@mui/icons-material/IosShare';
import Customers from '../../components/admin/Customers';
import Exports from '../../components/admin/Exports';

enum SubPage {
  Customers,
  Exports,
}

const Home: React.FC = () => {
  const { t } = useTranslation();

  const [subPage, setSubPage] = useState(SubPage.Customers);

  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid
        size={{ sm: 4, md: 3, lg: 2 }}
        sx={{
          bgcolor: 'secondary.main',
        }}
      >
        <Box>
          <List disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                selected={subPage === SubPage.Customers}
                onClick={() => setSubPage(SubPage.Customers)}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={t('admin.customers')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                selected={subPage === SubPage.Exports}
                onClick={() => setSubPage(SubPage.Exports)}
              >
                <ListItemIcon>
                  <IosShareIcon />
                </ListItemIcon>
                <ListItemText primary={t('admin.export')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid size={{ sm: 8, md: 9, lg: 10 }}>
        {subPage === SubPage.Customers && <Customers />}
        {subPage === SubPage.Exports && <Exports />}
      </Grid>
    </Grid>
  );
};

export default observer(Home);
