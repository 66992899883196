import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  useMainStore,
  useBillingStore,
  useAdminStore,
} from '../../providers/StoresProvider';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loader: React.FC = () => {
  const mainStore = useMainStore();
  const billingStore = useBillingStore();
  const adminStore = useAdminStore();

  return (
    <Backdrop
      open={mainStore.loader || billingStore.loader || adminStore.loader}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default observer(Loader);
