import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import { Button, Typography } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DateFormat from '../../../utils/DateFormat';
import { isAfter } from 'date-fns';
import AddLicenceForm from '../form/AddLicenceForm';

const LicenceList: React.FC = () => {
  const { t } = useTranslation();
  const adminStore = useAdminStore();

  const [showForm, setShowForm] = useState(false);

  const getFacilityName = (facilityId: number) => {
    return adminStore.customer?.facilities?.find((f) => f.id === facilityId)
      ?.name;
  };

  const handleAddLicence = () => {
    setShowForm(true);
  };

  const hasFacilities =
    adminStore.customer?.facilities !== undefined &&
    adminStore.customer?.facilities.length > 0;

  return (
    <Grid container spacing={4} p={2}>
      {hasFacilities && (
        <Grid size={12}>
          <Button variant="contained" onClick={handleAddLicence}>
            {t('admin.addLicence')}
          </Button>
        </Grid>
      )}
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('licence.facility')}</TableCell>
                <TableCell>{t('licence.beds')}</TableCell>
                <TableCell>{t('licence.validTo')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminStore.customer?.licences?.map((licence) => (
                <TableRow
                  key={licence.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{getFacilityName(licence.facilityId)}</TableCell>
                  <TableCell>
                    {licence.beds === -1 ? t('admin.unlimited') : licence.beds}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={
                        isAfter(licence.validTo, new Date())
                          ? 'success'
                          : 'error'
                      }
                    >
                      <DateFormat date={licence.validTo} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {hasFacilities && (
          <AddLicenceForm
            show={showForm}
            close={() => {
              setShowForm(false);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(LicenceList);
