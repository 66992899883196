import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';

import { useMainStore } from '../providers/StoresProvider';
import { UserRole } from '../api/models';

type Props = {
  needsAdminRole?: boolean;
};

const RequireAuth: React.FC<PropsWithChildren<Props>> = ({
  children,
  needsAdminRole = false,
}) => {
  const mainStore = useMainStore();

  let hasRole = true;

  if (needsAdminRole) {
    hasRole = mainStore.user?.role === UserRole.ADMIN;
  }

  if (mainStore.isAuthenticated && !mainStore.loaded) {
    return <></>;
  }

  if (mainStore.isAuthenticated && hasRole) {
    return <>{children}</>;
  } else {
    return <Navigate to="/app/users/login" />;
  }
};

export default observer(RequireAuth);
