import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation, Trans } from 'react-i18next';
import { useMainStore, useBillingStore } from '../../providers/StoresProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import DateFormat from '../../utils/DateFormat';

import { PaymentMethod, SubscriptionState } from '../../api/models';

const Finish: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const billingStore = useBillingStore();

  const handleGoBack = () => {
    window.open(process.env.REACT_APP_GO_BACK_LINK, '_blank');
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid size={{ xs: 12, sm: 8 }}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid size={12}>
              <Typography variant="h4" gutterBottom>
                {t('finish.thankYou')}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="h6" gutterBottom>
                <b>{t('finish.newLicences')}</b>
              </Typography>
              {billingStore.subscription?.licences.map((licence) => {
                const facility = billingStore.facilities?.find(
                  (f) => f.id === licence.facilityId,
                );

                return (
                  <Typography variant="body1" key={licence.id} gutterBottom>
                    <>
                      {facility?.name}
                      {' - '}
                      {billingStore.subscription?.state !==
                        SubscriptionState.trial
                        ? t('finish.renewvalOn')
                        : t('finish.validTo')}{' '}
                      <DateFormat date={billingStore.subscription?.endsAt} />
                    </>
                  </Typography>
                );
              })}
            </Grid>
            {billingStore.paymentMethod === PaymentMethod.CARD && (
              <Grid size={12}>
                {billingStore.subscription?.state !==
                  SubscriptionState.trial ? (
                  <Alert severity="success">{t('finish.chargedShortly')}</Alert>
                ) : (
                  <Alert severity="info">
                    <Box pb={2}>
                      <Trans
                        i18nKey="finish.chargedUntil"
                        values={{
                          dateUntil: billingStore.subscription?.endsAt,
                        }}
                        components={{ bold: <strong /> }}
                      />
                    </Box>
                    <Box>{t('finish.afterTrialExpires')}</Box>
                  </Alert>
                )}
              </Grid>
            )}
            {billingStore.paymentMethod === PaymentMethod.BANK_TRANSFER && (
              <Grid size={12}>
                <Alert severity="info">
                  <Box pb={2}>
                    <Trans
                      i18nKey="finish.mailInvoice"
                      values={{ email: mainStore.customer?.email }}
                      components={{ bold: <strong /> }}
                    />
                  </Box>
                  <Box pb={2} fontWeight="bold">
                    {t('finish.businessDays')}
                  </Box>
                  <Box>{t('finish.afterActivation')}</Box>
                </Alert>
              </Grid>
            )}
            <Grid size="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
              >
                {t('general.goBack')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default observer(Finish);
