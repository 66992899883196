import { makeObservable, observable, action, runInAction } from 'mobx';
import {
  Customer,
  User,
  Licence,
  Facility,
  LicenceRequest,
  PasswordChangeRequest,
  ExportType,
  StatusResponse,
  EvAccount,
  EtAccount,
} from '../api/models';
import {
  customersURL,
  customerUsersURL,
  customerFacilitiesURL,
  customerLicencesURL,
  userUndeleteURL,
  userPasswordChangeURL,
  exportURL,
  customerEvisitorsURL,
  customerEturizemAccountsURL,
  customerEvisitorURL,
  customerEturizemAccountURL,
} from '../api/urls';
import {
  getFile,
  getJSON,
  postJSON,
  patchJSON,
  deleteJSON,
} from '../api/engine';
import MainStore from './MainStore';

class AdminStore {
  mainStore: MainStore;

  // Observable
  loader = false;

  customers?: Customer[];
  filteredCustomers?: Customer[];

  customer?: Customer;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;

    makeObservable(this, {
      loader: observable,
      customers: observable,
      filteredCustomers: observable,
      customer: observable,

      getCustomers: action,
      searchCustomers: action,
      getCustomer: action,
      clearCustomer: action,
      createLicence: action,
      undeleteUser: action,
      changeUserPassword: action,
      export: action,
    });
  }

  // Actions

  getCustomers() {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      getJSON<Customer[]>(
        this.mainStore.baseUrl,
        customersURL(),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (response.success && response.data) {
            this.customers = response.data;
            this.filteredCustomers = response.data;
          }
          this.hideLoader();
        });
      });
    }
  }

  searchCustomers(value: string) {
    if (this.customers) {
      if (value === '') {
        this.filteredCustomers = [...this.customers];
      }

      const valueMatch = (field?: string) => {
        return (
          field && field.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        );
      };

      this.filteredCustomers = this.customers?.filter(
        (c) =>
          valueMatch(c.ownerFirstName) ||
          valueMatch(c.ownerSurname) ||
          valueMatch(c.companyName) ||
          valueMatch(c.email),
      );
    }
  }

  getCustomer(customerId: number) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      this.customer = this.customers?.find((c) => c.id === customerId);

      let getMiddlewareAccounts;

      if (this.mainStore.isCroatia) {
        getMiddlewareAccounts = getJSON<EvAccount[]>(
          this.mainStore.baseUrl,
          customerEvisitorsURL(customerId),
          undefined,
          this.mainStore.authToken,
        ).then((response) => {
          runInAction(() => {
            if (this.customer && response.success && response.data) {
              this.customer.evAccounts = response.data;
            }
          });
        });
      }

      if (this.mainStore.isSlovenia) {
        getMiddlewareAccounts = getJSON<EtAccount[]>(
          this.mainStore.baseUrl,
          customerEturizemAccountsURL(customerId),
          undefined,
          this.mainStore.authToken,
        ).then((response) => {
          runInAction(() => {
            if (this.customer && response.success && response.data) {
              this.customer.etAccounts = response.data;
            }
          });
        });
      }

      Promise.all([
        getMiddlewareAccounts,
        getJSON<User[]>(
          this.mainStore.baseUrl,
          customerUsersURL(customerId),
          undefined,
          this.mainStore.authToken,
        ).then((response) => {
          runInAction(() => {
            if (this.customer && response.success && response.data) {
              this.customer.users = response.data;
            }
          });
        }),
        getJSON<Facility[]>(
          this.mainStore.baseUrl,
          customerFacilitiesURL(customerId),
          undefined,
          this.mainStore.authToken,
        ).then((response) => {
          runInAction(() => {
            if (this.customer && response.success && response.data) {
              this.customer.facilities = response.data;
            }
          });
        }),
        getJSON<Licence[]>(
          this.mainStore.baseUrl,
          customerLicencesURL(customerId),
          undefined,
          this.mainStore.authToken,
        ).then((response) => {
          runInAction(() => {
            if (this.customer && response.success && response.data) {
              this.customer.licences = response.data.sort((a, b) =>
                b.validTo.localeCompare(a.validTo),
              );
            }
          });
        }),
      ]).then(() => {
        runInAction(() => {
          this.hideLoader();
        });
      });
    }
  }

  clearCustomer() {
    this.customer = undefined;
  }

  deleteEvisitor(customerId: number, id: number) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      deleteJSON<StatusResponse>(
        this.mainStore.baseUrl,
        customerEvisitorURL(customerId, id),
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (this.customer && response.success && response.data?.success) {
            this.customer.evAccounts = this.customer?.evAccounts?.filter(
              (a) => a.id !== id,
            );
          }
          this.hideLoader();
        });
      });
    }
  }

  deleteEturizemAccount(customerId: number, id: number) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      deleteJSON<StatusResponse>(
        this.mainStore.baseUrl,
        customerEturizemAccountURL(customerId, id),
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (this.customer && response.success && response.data?.success) {
            this.customer.etAccounts = this.customer?.etAccounts?.filter(
              (a) => a.id !== id,
            );
          }
          this.hideLoader();
        });
      });
    }
  }

  createLicence(
    customerId: number,
    postData: LicenceRequest,
    callback: (data: Licence | null) => void,
  ) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      postJSON<Licence, LicenceRequest>(
        this.mainStore.baseUrl,
        customerLicencesURL(customerId),
        postData,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (this.customer && response.success && response.data) {
            if (this.customer.licences === undefined) {
              this.customer.licences = [];
            }

            this.customer.licences.unshift(response.data);

            callback(response.data);
          } else {
            callback(null);
          }
          this.hideLoader();
        });
      });
    }
  }

  undeleteUser(userId: number) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      patchJSON<StatusResponse, void>(
        this.mainStore.baseUrl,
        userUndeleteURL(userId),
        undefined,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (this.customer && response.success && response.data) {
            const user = this.customer.users?.find((u) => u.id === userId);

            if (user) {
              user.deleted = false;
            }
          }
          this.hideLoader();
        });
      });
    }
  }

  changeUserPassword(
    userId: number,
    postData: PasswordChangeRequest,
    callback: (data: StatusResponse | null) => void,
  ) {
    if (this.mainStore.authToken !== null) {
      this.showLoader();

      patchJSON<StatusResponse, PasswordChangeRequest>(
        this.mainStore.baseUrl,
        userPasswordChangeURL(userId),
        postData,
        this.mainStore.authToken,
      ).then((response) => {
        runInAction(() => {
          if (this.customer && response.success && response.data) {
            callback(response.data);
          } else {
            callback(null);
          }
          this.hideLoader();
        });
      });
    }
  }

  export(type: ExportType) {
    if (this.mainStore.authToken !== null) {
      getFile(
        this.mainStore.baseUrl,
        exportURL(type),
        `${type}.csv`,
        this.mainStore.authToken,
      );
    }
  }

  showLoader() {
    this.loader = true;
  }

  hideLoader() {
    this.loader = false;
  }
}

export default AdminStore;
