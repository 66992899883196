import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAdminStore, useMainStore } from '../../../providers/StoresProvider';

import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const MiddlewareAccountList: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const adminStore = useAdminStore();

  const accounts = mainStore.isSlovenia
    ? adminStore.customer?.etAccounts
    : adminStore.customer?.evAccounts;

  const handleDeleteAccount = (id?: number) => {
    if (adminStore.customer && adminStore.customer.id && id) {
      if (window.confirm(t('general.confirmDelete'))) {
        if (mainStore.isSlovenia) {
          adminStore.deleteEturizemAccount(adminStore.customer.id, id);
        } else {
          adminStore.deleteEvisitor(adminStore.customer.id, id);
        }
      }
    }
  };

  return (
    <Grid container spacing={4} p={2}>
      <Grid size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('middlewareAccount.username')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts?.map((account) => (
                <TableRow
                  key={account.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{account.username}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteAccount(account.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default observer(MiddlewareAccountList);
