import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import createSGTheme from './sgTheme';

import { StoresProvider } from './providers/StoresProvider';

import Content from './Content';

const theme = createSGTheme();

function App() {
  return (
    <StoresProvider>
      <ThemeProvider theme={theme}>
        <Content />
      </ThemeProvider>
    </StoresProvider>
  );
}
export default App;
