import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBillingStore } from '../../providers/StoresProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const Error: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const billingStore = useBillingStore();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid size={{ xs: 12, sm: 8 }}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid size={12}>
              <Typography variant="h4" gutterBottom>
                {t('error.paymentFailed')}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography variant="h6" gutterBottom>
                <b>{t('error.info')}</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {billingStore.paymentError?.message}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Alert severity="warning">{t('error.contactUs')}</Alert>
            </Grid>
            <Grid size="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
              >
                {t('general.goBack')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default observer(Error);
