// ENUMS

export enum SubscriptionState {
  pending = 'pending',
  trial = 'trial',
  active = 'active',
  cancelled = 'cancelled',
}

export enum PaymentMethod {
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum ExportType {
  HOSTS = 'hosts',
  FACILITIES = 'facilities',
  USERS = 'users',
  REFERRALS = 'referrals',
}

// MODELS

export interface LoginRequest {
  email: string;

  password: string;
}

export interface PasswordResetRequest {
  email: string;
}

export interface PasswordChangeRequest {
  password: string;

  passwordRepeat?: string;
}

export interface RegistrationRequest {
  password: string;

  email: string;

  phone: string;

  agree: boolean;
}

export interface StatusResponse {
  success: boolean;
}

export interface LoginResponse {
  email: string;

  token: string;
}

export interface LicenceRequest {
  facilityId: number;

  validTo: string;
}

export interface SubscriptionRequest {
  facilityIds: number[];

  paymentMethod: PaymentMethod;

  couponCode?: string;
}

export interface User {
  id?: number;

  customerId?: number;

  email?: string;

  role?: UserRole;

  lastLoginDevice?: string;

  lastLoginAt?: string;

  createdAt?: string;

  deleted?: boolean;
}

export interface Customer {
  id?: number;

  ownerId?: number;

  email?: string;

  phone?: string;

  taxSubject?: boolean;

  companyName?: string;

  companyIdentificationNumber?: string;

  ownerFirstName?: string;

  ownerSurname?: string;

  ownerIdentificationNumber?: string;

  address?: string;

  city?: string;

  zipCode?: string;

  countryId?: number;

  users?: User[];

  evAccounts?: EvAccount[];

  etAccounts?: EtAccount[];

  facilities?: Facility[];

  licences?: Licence[];
}

export interface ReferralInfo {
  invited: boolean;

  referrals: number[];

  bonusDays: number;
}

export interface LicencePaymentInfo {
  facilityId: number;

  facilityName: string;

  beds: number;

  minBeds: number;

  maxBeds: number;

  validFrom: string;

  validTo: string;

  price: number;

  priceId: string;
}

export interface CouponInfo {
  valid: boolean;

  serviceId: string;

  amountOff: number;

  percentOff: number;
}

export interface CouponInfoFetchParams {
  code: string;
}

export interface Licence {
  id: number;

  facilityId: number;

  beds: number;

  validTo: string;
}

export interface LicenceFetchParams {
  valid: boolean;
}

export interface Subscription {
  id: number;

  customerId: number;

  state: SubscriptionState;

  serviceId: string;

  serviceCustomerId: string;

  serviceClientSecret: string;

  endsAt: string;
}

export interface Facility {
  id: number;

  name: string;

  type?: string;

  settlement?: string;

  streetNumber?: string;

  settlementZone?: string;

  latitude?: number;

  longitude?: number;

  info?: string;

  checkoutTime?: string;

  showOnCalendar?: boolean;

  licences?: Licence[];
}

export interface Country {
  id: number;

  alpha2: string;

  alpha3: string;

  name: string;
}

export interface EvAccount {
  id: number;

  username: string;
}

export interface EtAccount {
  id: number;

  username: string;
}
