import i18n from 'i18next';
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

interface ApiResponse<R> {
  success: boolean;
  data?: R;
}

const getHeaders = (authToken?: string) => {
  const headers: RawAxiosRequestHeaders = {
    'Accept-Language': i18n.language,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return headers;
};

export function getJSON<R, P = void>(
  baseUrl: string,
  url: string,
  params?: P,
  authToken?: string,
): Promise<ApiResponse<R>> {
  let config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  if (params) {
    config = { ...config, params: params };
  }

  return new Promise((resolve) => {
    axios
      .get<R>(`${baseUrl}${url}`, config)
      .then((res) => {
        resolve({
          success: true,
          data: res.data,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
        console.error(err);
      });
  });
}

export function postJSON<R, D>(
  baseUrl: string,
  url: string,
  data: D,
  authToken?: string,
): Promise<ApiResponse<R>> {
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  return new Promise((resolve) => {
    axios
      .post<R>(`${baseUrl}${url}`, data, config)
      .then((res) => {
        resolve({
          success: true,
          data: res.data,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
        console.error(err);
      });
  });
}

export function putJSON<R, D>(
  baseUrl: string,
  url: string,
  data: D,
  authToken?: string,
): Promise<ApiResponse<R>> {
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  return new Promise((resolve) => {
    axios
      .put<R>(`${baseUrl}${url}`, data, config)
      .then((res) => {
        resolve({
          success: true,
          data: res.data,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
        console.error(err);
      });
  });
}

export function patchJSON<R, D>(
  baseUrl: string,
  url: string,
  data: D,
  authToken?: string,
): Promise<ApiResponse<R>> {
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  return new Promise((resolve) => {
    axios
      .patch<R>(`${baseUrl}${url}`, data, config)
      .then((res) => {
        resolve({
          success: true,
          data: res.data,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
        console.error(err);
      });
  });
}

export function deleteJSON<R>(
  baseUrl: string,
  url: string,
  authToken?: string,
): Promise<ApiResponse<R>> {
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  return new Promise((resolve) => {
    axios
      .delete<R>(`${baseUrl}${url}`, config)
      .then((res) => {
        resolve({
          success: true,
          data: res.data,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
        console.error(err);
      });
  });
}

export function getFile(
  baseUrl: string,
  url: string,
  filename: string,
  authToken?: string,
) {
  const config: AxiosRequestConfig = {
    headers: getHeaders(authToken),
    responseType: 'blob',
  };

  axios
    .get(`${baseUrl}${url}`, config)
    .then((res) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(res.data);

      // create link HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // clean up link element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      console.error(err);
    });
}
