import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useMainStore, useBillingStore } from '../../providers/StoresProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import Referrals from '../../components/billing/Referrals';
import Licence from '../../components/billing/Licence';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();
  const billingStore = useBillingStore();

  useEffect(() => {
    if (billingStore.countries === null) {
      billingStore.getCountries();
    }
    if (billingStore.facilities === null) {
      billingStore.getFacilities();
    }
    if (billingStore.licences === null) {
      billingStore.getLicences();
    }
    if (billingStore.licencePaymentInfos === null) {
      billingStore.getSubscriptionInfo();
    }
    if (billingStore.referralInfo === null) {
      billingStore.getReferralsInfo();
    }
    if (billingStore.subscriptions === null) {
      billingStore.getSubscriptions();
    }
  }, [billingStore]);

  const isLoaded = mainStore.user && mainStore.customer;

  const isOwner =
    mainStore.user &&
    mainStore.customer &&
    mainStore.user.id === mainStore.customer.ownerId;

  const handleClose = () => {
    window.open(process.env.REACT_APP_GO_BACK_LINK, '_blank');
  };

  return (
    <Container maxWidth="md">
      {isLoaded ? (
        isOwner ? (
          <Grid container justifyContent="center" spacing={10}>
            <Grid size={12}>
              <Referrals />
            </Grid>
            <Grid size={12}>
              <Licence />
            </Grid>
            <Grid size="auto" sx={{ display: { sm: 'none', xs: 'block' } }}>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                {t('general.close')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid size="auto">
              <Alert severity="warning">{t('home.notOwner')}</Alert>
            </Grid>
          </Grid>
        )
      ) : (
        <div></div>
      )}
    </Container>
  );
};

export default observer(Home);
