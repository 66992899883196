import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMainStore } from '../../../providers/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';

import ServerSelect from '../../general/ServerSelect';

import { RegistrationRequest } from '../../../api/models';

const RegistrationForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mainStore = useMainStore();

  const [showError, setShowError] = useState(false);

  const validationSchema = yup.object({
    phone: yup.string().required(t('validation.required')),
    email: yup
      .string()
      .email(t('validation.email'))
      .required(t('validation.required')),
    password: yup
      .string()
      .required(t('validation.required'))
      .min(6, t('validation.minLength')),
    agree: yup.bool().oneOf([true]),
  });

  const formik = useFormik<RegistrationRequest>({
    initialValues: {
      phone: '',
      email: '',
      password: '',
      agree: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const refUser = searchParams.get('ref_user');
      const refCustomer = searchParams.get('ref_customer');

      mainStore.register(refUser, refCustomer, values, (success) => {
        formik.resetForm();
        if (success) {
          navigate('/app/install');
        } else {
          setShowError(true);
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid>
          <Grid container spacing={4}>
            <Grid size={12}>
              <Typography variant="h4">{t('auth.register')}</Typography>
            </Grid>
            <Grid size={12}>
              <ServerSelect />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="phone"
                id="phone"
                name="phone"
                label={t('auth.phone')}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label={t('auth.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                id="password"
                name="password"
                label={t('auth.password')}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid size={12}>
              <FormControlLabel
                id="agree"
                name="agree"
                onChange={formik.handleChange}
                control={<Checkbox checked={formik.values.agree} />}
                label={t('auth.terms')}
              />
            </Grid>
            {showError && (
              <Grid size={12}>
                <Alert severity="error">{t('auth.emailUsed')}</Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid size="auto">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.values.agree}
          >
            {t('auth.register')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegistrationForm;
