import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useMainStore } from '../../../providers/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import ServerSelect from '../../general/ServerSelect';

import { PasswordChangeRequest } from '../../../api/models';

const ChangePasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const mainStore = useMainStore();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const email = searchParams.get('email');

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t('validation.required'))
      .min(6, t('validation.minLength')),
    passwordRepeat: yup
      .string()
      .required(t('validation.required'))
      .oneOf([yup.ref('password')], t('validation.passwordMatch')),
  });

  const formik = useFormik<PasswordChangeRequest>({
    initialValues: {
      password: '',
      passwordRepeat: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const token = searchParams.get('token');
      if (token !== null) {
        delete values['passwordRepeat'];
        mainStore.changePassword(token, values, (success) => {
          formik.resetForm();
          if (success) {
            setShowSuccess(true);
          } else {
            setShowError(true);
          }
        });
      } else {
        setShowError(true);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid>
          <Grid container spacing={4}>
            <Grid size={12}>
              <Typography variant="h4">{t('auth.changePassword')}</Typography>
            </Grid>
            <Grid size={12}>
              <Alert severity="info">
                {t('auth.changePasswordInfo')}
                {': '}
                <b>{email}</b>
              </Alert>
            </Grid>
            <Grid size={12}>
              <ServerSelect />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                id="password"
                name="password"
                label={t('auth.password')}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                id="passwordRepeat"
                name="passwordRepeat"
                label={t('auth.passwordRepeat')}
                value={formik.values.passwordRepeat}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordRepeat &&
                  Boolean(formik.errors.passwordRepeat)
                }
                helperText={
                  formik.touched.passwordRepeat && formik.errors.passwordRepeat
                }
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            </Grid>
            {showError && (
              <Grid size={12}>
                <Alert severity="error">{t('auth.invalidToken')}</Alert>
              </Grid>
            )}
            {showSuccess && (
              <Grid size={12}>
                <Alert severity="success">{t('auth.passwordChanged')}</Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid size="auto">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={showSuccess}
          >
            {t('general.change')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
