import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../../../providers/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { PasswordChangeRequest } from '../../../api/models';
import Grid from '@mui/material/Grid2';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

type Props = {
  userId: number;
  show: boolean;
  close: () => void;
};

const ChangePasswordForm: React.FC<Props> = ({ userId, show, close }) => {
  const { t } = useTranslation();
  const adminStore = useAdminStore();

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t('validation.required'))
      .min(6, t('validation.minLength')),
    passwordRepeat: yup
      .string()
      .required(t('validation.required'))
      .oneOf([yup.ref('password')], t('validation.passwordMatch')),
  });

  const formik = useFormik<PasswordChangeRequest>({
    initialValues: {
      password: '',
      passwordRepeat: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      delete values['passwordRepeat'];
      adminStore.changeUserPassword(userId, values, (success) => {
        formik.resetForm();
        if (success) {
          close();
        }
      });
    },
  });

  return (
    <Dialog
      open={show}
      onClose={close}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        },
      }}
    >
      <DialogTitle>{t('admin.changePassword')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4} pt={2}>
          <Grid size={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              id="password"
              name="password"
              label={t('auth.password')}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              id="passwordRepeat"
              name="passwordRepeat"
              label={t('auth.passwordRepeat')}
              value={formik.values.passwordRepeat}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordRepeat &&
                Boolean(formik.errors.passwordRepeat)
              }
              helperText={
                formik.touched.passwordRepeat && formik.errors.passwordRepeat
              }
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('general.close')}</Button>
        <Button type="submit">{t('general.change')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordForm;
