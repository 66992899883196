import React from 'react';

import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';

const User: React.FC = () => {
  return (
    <Container maxWidth="xs">
      <Grid container direction="column">
        <Grid size={12} pt={6} pb={6}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default User;
