import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainStore } from '../../../providers/StoresProvider';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import ServerSelect from '../../general/ServerSelect';

import { PasswordResetRequest } from '../../../api/models';

const ResetPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const mainStore = useMainStore();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('validation.email'))
      .required(t('validation.required')),
  });

  const formik = useFormik<PasswordResetRequest>({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mainStore.resetPassword(values, (success) => {
        formik.resetForm();
        if (success) {
          setShowSuccess(true);
        } else {
          setShowError(true);
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid>
          <Grid container spacing={4}>
            <Grid size={12}>
              <Typography variant="h4">{t('auth.resetPassword')}</Typography>
            </Grid>
            <Grid size={12}>
              <Alert severity="info">{t('auth.resetPasswordInfo')}</Alert>
            </Grid>
            <Grid size={12}>
              <ServerSelect />
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label={t('auth.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {showError && (
              <Grid size={12}>
                <Alert severity="error">{t('auth.invalidEmail')}</Alert>
              </Grid>
            )}
            {showSuccess && (
              <Grid size={12}>
                <Alert severity="success">
                  {t('auth.resetPasswordLinkSend')}
                </Alert>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid size="auto">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={showSuccess}
          >
            {t('general.reset')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPasswordForm;
